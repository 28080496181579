<template>
    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <div class="d-flex flex-row align-items-center mb-3" style="gap: 0px">
            <BackButton />
            <h1 v-if="conversation" class="m-0 text-light">
                <span style="font-weight: 500">{{
                    t('pages.messages.captain')
                }}</span>
                <!-- {{ conversation.person.first_name }} -->
                {{ ' ' + conversation.person.last_name }}
            </h1>
            <h1 class="m-0 text-light" v-else>
                {{ t('pages.messages.notFound') }}
            </h1>
        </div>
        <div v-if="!settings.disable_messaging">
            <div
                v-if="conversation"
                class="card shadow-sm p-3 d-flex flex-column"
                style="min-height: calc(100vh - 10rem)"
            >
                <div
                    class="flex-grow-1"
                    ref="chat"
                    style="overflow-y: scroll; max-height: calc(100vh - 16rem)"
                    v-if="conversation.messages.length != 0"
                >
                    <div
                        v-if="startOfConversation"
                        style="width: 100%; margin: 10px 0 20px"
                        class="text-muted text-center mb-4"
                    >
                        <div
                            class="d-inline bg-white px-2"
                            style="font-weight: 500"
                        >
                            {{
                                t(
                                    'pages.messages.viewConversation.startOfConvo'
                                )
                            }}
                        </div>
                    </div>
                    <div v-if="loading" class="text-center">
                        <img
                            src="@/assets/loading-icon.gif"
                            style="width: 1.5rem; height: 1.5rem"
                        />
                    </div>
                    <div
                        v-for="(message, i) in positionedMessages
                            .slice()
                            .reverse()"
                        :key="message.id"
                        class="d-flex flex-column"
                        :ref="
                            (el) => {
                                if (i == 0) {
                                    firstBubble = el;
                                }
                            }
                        "
                    >
                        <div :class="message.class" class="mb-3">
                            <div class="bubble mb-0">
                                {{ message.message }}
                            </div>
                            <div class="text-muted timestamp mt-1">
                                {{
                                    moment(message.timestamp).format(
                                        'Do MMMM HH:mm'
                                    )
                                }}
                                <img
                                    :src="[
                                        message.read ? ReadIcon : UnreadIcon,
                                    ]"
                                    style="width: 15px"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="flex-grow-1">
                    <div
                        style="width: 100%; margin: 10px 0 20px"
                        class="w-100 text-muted text-center mb-4"
                    >
                        <div
                            class="d-inline bg-white px-2"
                            style="font-weight: 500"
                        >
                            {{ t('pages.messages.viewConversation.startWith') }}
                            {{ conversation.person.last_name }}
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-row pt-3" style="gap: 0.2rem">
                    <input
                        v-model.trim="currentMessage"
                        @keydown.enter="sendMessage()"
                        :placeholder="
                            t('pages.messages.viewConversation.typeSomething')
                        "
                        type="text"
                        class="form-control message-input bg-muted rounded-pill"
                    />
                    <button @click="sendMessage()" class="btn btn-primary">
                        {{ t('pages.messages.viewConversation.button') }}
                    </button>
                </div>
            </div>
            <div class="card p-3" v-else>
                <h5>{{ t('pages.messages.cantFindConversation') }}</h5>
                <p>
                    {{ t('pages.messages.notFoundDesc') }}
                </p>
                <button class="btn btn-primary" @click="router.back()">
                    {{ t('misc.goBack') }}
                </button>
            </div>
        </div>
        <div v-else class="card p-3 shadow-sm mb-3">
            <h5>Undergoing maintenance</h5>
            <p>
                We're sorry, but we're currently performing maintenance on the
                messaging system to provide the best experience possible. We're
                sorry for any inconvenience and we will be back as soon as
                possible.
            </p>

            <a href="#" class="btn btn-primary" onclick="location.reload()"
                >Check again</a
            >
        </div>
    </div>
</template>

<script setup>
import BackButton from '@/components/BackButton';
import { useRoute, useRouter } from 'vue-router';
import { useMessageStore } from '@/stores/messages-store';
import { useUserStore } from '@/stores/user-store';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, inject, nextTick } from 'vue';
import { emitter } from '@/mitt';
import axios from 'axios';
import { useI18n } from 'vue-i18n';
import UnreadIcon from '@/assets/tick-unread.svg';
import ReadIcon from '@/assets/tick-read.svg';
import { useRemoteSettingsStore } from '@/stores/remote-settings-store';

const router = useRouter();

const remoteSettingsStore = useRemoteSettingsStore();
const { settings } = storeToRefs(remoteSettingsStore);

const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');

const { t } = useI18n();
const moment = inject('moment');
const route = useRoute();
const startOfConversation = ref(false);
const loading = ref(true);
const messageStore = useMessageStore();
const { conversations } = storeToRefs(messageStore);
const currentMessage = ref(null);
const chat = ref(null);
let firstBubble = ref(null);

const nextPageUrlToFetchFrom = ref(null);

const chattingToId = computed(() => {
    // this usually breaks on page change because route.params.id
    // becomes null
    if (
        conversations.value &&
        conversations.value[route.params.id] &&
        conversations.value[route.params.id].person
    )
        return conversations.value[route.params.id].person.id;
    return null;
});

const conversation = computed(() => {
    return conversations.value[route.params.id];
});

const positionedMessages = computed(() => {
    var classAdded = [];
    if (!conversation.value) return [];
    conversation.value.messages.forEach((message) => {
        var current = {};
        current.id = message.id;
        current.read = message.read;
        current.message = message.message;
        current.timestamp = message.created_at;
        if (message.from_user_id == chattingToId.value) {
            current.class = 'text-start left';
        } else {
            current.class = 'text-end right';
        }
        classAdded.push(current);
    });
    return classAdded;
});

async function sendMessage() {
    remoteSettingsStore.fetchSettings();
    const userStore = useUserStore();
    const loggedInUseId = storeToRefs(userStore).user.value.id;
    const text = currentMessage.value;
    currentMessage.value = '';
    if (currentMessage.value != '' || currentMessage.value != null) {
        conversation.value.messages.unshift({
            message: text,
            read: false,
            from_user_id: loggedInUseId,
            to_user_id: chattingToId,
        });
        emitter.emit('update-messages');
        await axios.post(`/messages/send/${chattingToId.value}`, {
            message: text,
        });
    }
}

async function fetchMessages() {
    remoteSettingsStore.fetchSettings();
    loading.value = true;
    let fetchedMessages = await axios.get(`messages/get/${chattingToId.value}`);
    conversation.value.messages = fetchedMessages.data.data;
    loading.value = false;
    scrollToBottom();
    startOfConversation.value = fetchedMessages.data.next_page_url == null;
    nextPageUrlToFetchFrom.value = fetchedMessages.data.next_page_url;
}

async function loadMoreMessages() {
    // load next page of messages
    remoteSettingsStore.fetchSettings();
    if (nextPageUrlToFetchFrom.value) {
        loading.value = true;
        let moreMessages = await axios.get(nextPageUrlToFetchFrom.value);
        if (moreMessages.data.data) {
            conversation.value.messages.push(...moreMessages.data.data);
            chat.value.scrollTop = firstBubble.value.offsetTop;
            startOfConversation.value = moreMessages.data.next_page_url == null;
            nextPageUrlToFetchFrom.value = moreMessages.data.next_page_url;
        }
        loading.value = false;
    }
}

emitter.on('update-messages', () => {
    scrollToBottom();
    remoteSettingsStore.fetchSettings();
    // Check if the component is still mounted, if not, don't do readall
    if (chat.value) {
        axios.put(`messages/readall/${chattingToId.value}`);
        conversation.value.has_unread = false;
    }
});

onMounted(() => {
    remoteSettingsStore.fetchSettings();
    if (conversation.value) {
        fetchMessages();
        scrollToBottom();
        if (chat.value) {
            chat.value.addEventListener('scroll', () => {
                if (chat.value.scrollTop == 0) {
                    loadMoreMessages();
                }
            });
        }
    }
});

function scrollToBottom() {
    nextTick(() => {
        if (chat.value) {
            chat.value.scrollTop = chat.value.scrollHeight + 1000;
        }
    });
}
</script>

<style scoped>
.timestamp {
    font-size: 0.8rem;
}

.bubble {
    --r: 25px; /* the radius */
    --t: 0px; /* the size of the tail */
    display: inline-block;
    max-width: 300px;
    /*padding: calc(2 * var(--r) / 2);*/
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 30px;
    background: var(--bs-dark);
    color: var(--bs-light);
}

.left {
    --_d: 0%;
    border-left: var(--t) solid #0000;
    margin-right: var(--t);
    place-self: start;
}

.right {
    --_d: 100%;
    border-right: var(--t) solid #0000;
    margin-left: var(--t);
    place-self: end;
}
</style>
